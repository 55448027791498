<template>
    <div class="home">
        <div style="display: flex; margin-top: 45px;margin-left: 80px">
            <div style="color: rgba(34,52,100,1);font-size: 28px;text-align: center;font-weight: bold; ">
                7×24h 财经直播</div>
            <el-input placeholder="新闻搜索" v-model="newsSearchContent" class="newsSearch">
                <el-button slot="append" icon="el-icon-search" @click="NewsSearch" :loading="isSearching"></el-button>
            </el-input>
            <div class="notice" hidden="true">
                <div class="notice__inner">
                    <div class="notice__item" v-for="ann in announcement" :key="ann.id">{{ ann.text }}</div>
                </div>
            </div>
        </div>
        <div style="display: flex; margin-left: 80px;margin-top: 20px;  align-items: flex-end; ">
            <el-checkbox-group @change="GetStartNews" size="medium" v-model="newsCheckboxGroup">
                <el-checkbox-button v-for="newsType in newsTypes" :label="newsType" :key="newsType">{{ newsType
                    }}</el-checkbox-button>
            </el-checkbox-group>
        </div>
        <div style="display: flex; margin-top: 30px;margin-left: 80px">
            <!--实时资讯页-->
            <div style="width: calc(50% - 00px);">
                <div v-if="!isShowNewsSearch">
                    <div style="display: flex;align-items: flex-end; justify-content: space-between;">
                        <div style="display: flex; flex-direction:row;align-items: flex-end;">
                            <div style=" font-weight: bold; color: rgba(79,79,79,1);font-size: 24px;">
                                实时资讯
                            </div>
                            <div style="display: flex; flex-direction:row;align-items:center;margin-bottom: 1px;">
                                <div
                                    style="color: rgba(79,79,79,1);margin-left: 5px;margin-right: 5px;font-size: Base;">
                                    更新中
                                </div>
                                <div class="loading-dots">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex;align-items: flex-end; ">
                            <!-- 新增下拉菜单 -->
                            <el-dropdown style="margin-right: 10px;" :hide-on-click="false">
                                <span
                                    style="color: rgba(79,79,79,1); margin-left: 5px; margin-right: 5px; font-size: Base; cursor: pointer;">
                                    <i class="el-icon-bell"></i> 通知设置
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item>
                                        <el-checkbox v-model="normalNewsNotificationSettings.sound"
                                            @change="updateNormalNewsSettings(normalNewsNotificationSettings)">声音提示</el-checkbox>
                                    </el-dropdown-item>
                                    <el-dropdown-item>
                                        <el-checkbox v-model="normalNewsNotificationSettings.voice"
                                            @change="updateNormalNewsSettings(normalNewsNotificationSettings)">语音播报</el-checkbox>
                                    </el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <div style="margin-right: 50px; color: rgba(79,79,79,1);text-align: left;font-size: 14px;">
                                {{ currentTime }}</div>
                        </div>
                    </div>

                    <hr>
                    <div class="news">
                        <div class="red-line"></div>
                        <div style=" width: 100%;margin-left: -10px ;" v-for="news in newsList"
                            :key="news.content + news.title">
                            <CommonSingleNews :news="news" :isRedImportentNews="true"></CommonSingleNews>
                        </div>
                    </div>
                    <el-button style="margin-top: 20px; margin-bottom: 30px; margin-left: 40%;widows: 80px;"
                        :loading="AddMoreNewsLoading" @click="AddMoreNews" type="primary">加载更多</el-button>
                </div>
                <div v-else>
                    <div style="display: flex; align-items: center;">
                        <div style="margin-left: 20px;margin-right: 20px; color: rgba(79,79,79,1); font-size: small;">
                            <div v-if="isSearching"> 数据搜索中... </div>
                            <div v-else> 共查询到{{ newsSearchAllCount }}条关于"{{ newsSearchContent }}"的数据。 </div>
                        </div>
                        <div style="display: flex;">
                            <el-button @click="CancelNewsSearch" size="small">取消筛选</el-button>
                            <el-button @click="AddToSearchList" size="small">加入检索清单</el-button>
                        </div>
                    </div>
                    <hr>
                    <div class="news">
                        <div class="red-line"></div>
                        <div style=" width: 100%;margin-left: -10px ;" v-for="news in newsSearchList"
                            :key="news.content">
                            <CommonSingleNews :news="news" :search-text="newsSearchContent"
                                :is-show-news-search="isShowNewsSearch"></CommonSingleNews>
                        </div>
                    </div>
                    <el-button v-if="newsSearchAllCount / 20 > newsSearchIndex"
                        style="margin-top: 20px; margin-bottom: 30px; margin-left: 40%;widows: 80px;"
                        :loading="AddMoreNewsSearchLoading" @click="AddMoreNewsSearch" type="primary">加载更多</el-button>
                    <div v-if="newsSearchAllCount / 20 <= newsSearchIndex"
                        style="display: flex; justify-content: center; padding-bottom: 10px;color: rgba(99,99,99,1);font-size: small;">
                        没有更多数据啦！</div>
                </div>
            </div>
            <!--我的关注页-->
            <div style="width: calc(50% - 80px);">
                <div style="display: flex;align-items: flex-end; justify-content: space-between;">
                    <div style="display: flex; flex-direction:row;align-items: flex-end;">
                        <div style="color: rgba(0,51,108,1);font-weight: bold;font-size: 24px;">我的关注
                        </div>
                        <div v-if="userInfo"
                            style="display: flex; flex-direction:row;align-items:center;margin-bottom: 1px;">
                            <div style="color: rgba(79,79,79,1);margin-left: 5px;margin-right: 5px;">检索中
                            </div>
                            <div class="loading-dots">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="userInfo" style="display: flex;  justify-content: center;  align-items: center;  ">
                        <!-- 新增下拉菜单 -->
                        <el-dropdown style="margin-right: 10px;" :hide-on-click="false">
                            <span style="color: rgba(79,79,79,1);margin-left: 5px;margin-right: 5px;">
                                <i class="el-icon-bell"></i> 通知设置
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item>
                                    <el-checkbox v-model="alertNewsNotificationSettings.sound"
                                        @change="updateAlertNewsSettings(alertNewsNotificationSettings)">声音提示</el-checkbox>
                                </el-dropdown-item>
                                <el-dropdown-item>
                                    <el-checkbox v-model="alertNewsNotificationSettings.voice"
                                        @change="updateAlertNewsSettings(alertNewsNotificationSettings)">语音播报</el-checkbox>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button size="small" @click="ShowNewsAlert" style="margin-right: 40px;">检索清单</el-button>
                    </div>

                </div>
                <hr style="margin-bottom: 0px;">
                <div class="userFocus">
                    <!-- 未登录页面 -->
                    <div v-if="!userInfo"
                        style="display: flex; flex-direction: column; align-items: center; justify-content: center;  padding-top: 50%;margin-top: -10px">
                        <div style="display: flex;align-content: center;">
                            <i :class="`el-icon-thumb`"
                                style=" font-size: 30px;color: rgba(0,51,108,1);transform: rotate(90deg);"></i>
                            <div style="margin-left: 10px; color: rgba(0,51,108,1);font-size: 28px;text-align: left; font-style: italic;text-decoration: underline; cursor: pointer;"
                                @click=" setUserLoginDialogVisible(true)">
                                登录 </div>
                        </div>
                        <div style="color: rgba(0,51,108,1);font-size: large;text-align: center;margin-top: 20px;">
                            无关资讯太多？点击登录定制专属推送
                        </div>
                    </div>
                    <div v-else>
                        <div class="news">
                            <div v-if="alertNewsList" class="blue-line"></div>
                            <div style=" width: 100%;margin-left: -10px ;" v-for="news in alertNewsList"
                                :key="news.content">
                                <CommonSingleNews :news="news" :isRedImportentNews="true"></CommonSingleNews>
                            </div>
                        </div>
                        <el-button v-if="maxAlertNewsIndex > alertNewsIndex"
                            style="margin-top: 20px; margin-bottom: 30px; margin-left: 40%;widows: 80px;"
                            :loading="AddMoreNewsAlertLoading" @click="AddMoreAlertNews" type="primary">加载更多</el-button>
                        <div v-if="maxAlertNewsIndex <= alertNewsIndex"
                            style="display: flex; justify-content: center; padding-bottom: 10px;color: rgba(99,99,99,1);font-size: small;">
                            没有更多数据啦！</div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" height="520px" width="450px" :before-close="handleClose">
            <CommonLogin></CommonLogin>
        </el-dialog>
        <el-dialog :visible.sync="dialogNewsAlertVisible" width="900px">
            <CommonUserNewsAlert></CommonUserNewsAlert>
            <el-button slot="footer" type="primary" @click="dialogNewsAlertVisible = false" size="small">关闭</el-button>
        </el-dialog>
        <el-alert v-if="connectionError" title="数据连接失败，请刷新网络。" type="error" show-icon></el-alert>
    </div>
</template>


<script>
import SignalRService from '../signalr/signalrService';
import { GetDbNewsProcessedContentSearch, GetNewData, GetAlertNews, InsertNewsAlert } from "../api";
import { mapState, mapMutations } from 'vuex';
import { EventBus } from '../main'; // 确保路径正确
import Cookie from 'js-cookie';
import moment from 'moment';
import CommonLogin from "../components/CommonLogin.vue";
import CommonUserNewsAlert from "../components/CommonUserNewsAlert.vue";
import CommonSingleNews from '../components/CommonSingleNews.vue';

export default {
    data() {
        return {
            announcement: [{ id: 1, text: '双击新闻可查看详细信息' },
            { id: 2, text: '【投资者互动】可查看上交所/深交所互动信息' },
            { id: 3, text: '双击新闻可查看详细信息' },
            { id: 4, text: '【投资者互动】可查看上交所/深交所互动信息' }],
            currentTime: '', // 当前日期
            newsSearchContent: '',
            newsCheckboxGroup: ['新闻资讯'],
            newsTypes: ['新闻资讯', '公司公告', '投资者互动', '政府公开'],
            newsList: [], // 实时获取的新闻
            AddMoreNewsLoading: false,
            AddMoreNewsSearchLoading: false,
            AddMoreNewsAlertLoading: false,
            dialogVisible: false,
            dialogNewsAlertVisible: false,
            alertNewsIndex: 1,
            isShowNewsSearch: false, // 显示用户搜索的新闻
            isSearching: false, // 搜索中的状态
            newsSearchList: [], // 用户搜索的新闻
            newsSearchIndex: 1, // 用户搜索的新闻当前页面
            newsSearchAllCount: 0, // 用户搜索的新闻总数
            connectionError: false, // 数据连接错误状态
            normalNewsNotificationSettings: this.getNotificationSettings('normalNewsNotificationSettings'),
            alertNewsNotificationSettings: this.getNotificationSettings('alertNewsNotificationSettings'),
            normalNewsAudio: new Audio(require('@/assets/NormalNews.wav')), // 添加普通新闻音频文件的引用
            alertNewsAudio: new Audio(require('@/assets/AlertNews.wav')), // 添加用户提醒新闻音频文件的引用
            newsQueue: [], // 消息队列
            isPlaying: false, // 是否正在播放
            alertNewsSummaries: new Set(), // 确保已初始化
        };
    },
    methods: {
        // 新闻检索      
        NewsSearch() {
            this.isSearching = true; // 开始搜索，显示加载动画
            this.isShowNewsSearch = true;
            GetDbNewsProcessedContentSearch(this.newsSearchContent, this.newsSearchIndex).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    console.log(data.data.data);
                    this.newsSearchAllCount = data.data.data.count;
                    this.newsSearchList = data.data.data.newsPreprocessList;
                }
                this.isSearching = false; // 数据请求完成，隐藏加载动画
            }).catch(() => {
                this.isSearching = false; // 请求失败，隐藏加载动画
            });
        },

        // 添加更多检索的新闻
        AddMoreNewsSearch() {
            this.AddMoreNewsSearchLoading = true;
            GetDbNewsProcessedContentSearch(this.newsSearchContent, this.newsSearchIndex + 1).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    this.newsSearchAllCount = data.data.data.count;
                    this.newsSearchList.push(...data.data.data.newsPreprocessList);
                    this.newsSearchIndex = this.newsSearchIndex + 1;
                    this.AddMoreNewsSearchLoading = false;
                }
            });
        },
        // 取消新闻检索
        CancelNewsSearch() {
            this.isShowNewsSearch = false;
            this.newsSearchList = []; // 用户搜索的新闻
            this.newsSearchIndex = 1; // 用户搜索的新闻当前页面
            this.newsSearchAllCount = 0; // 用户搜索的新闻总数
            this.newsSearchContent = ''; // 用户搜索的新闻总数
        },
        // 加入检索清单
        async AddToSearchList() {
            if (!this.userInfo) {
                this.setUserLoginDialogVisible(true);
                this.ErrorMessage('加入检索清单前请登录');
            } else {
                const newsAlertList = [{
                    id: 0, // 生成新的ID，确保唯一性
                    updateTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSSS'), // 当前时间
                    alertType: '关键词',
                    alertStr: this.newsSearchContent
                }];
                try {
                    const data = await InsertNewsAlert(newsAlertList);
                    if (data.data.message && data.data.message.length > 0) {
                        this.ErrorMessage(data.data.message);
                    }
                } catch (error) {
                    this.ErrorMessage('加入检索清单失败，请检查网络');
                }
            }
        },
        ToLocalISOString(date) {
            const offset = date.getTimezoneOffset() * 60000; // 转换为毫秒
            const localISOTime = (new Date(date - offset)).toISOString().slice(0, -1);
            return localISOTime + 'Z';
        },
        // 初始化新闻
        async GetStartNews() {
            // token存入Cookie
            Cookie.set('newsCheckboxGroup', JSON.stringify(this.newsCheckboxGroup), { expires: 31 }); // 保存一月
            GetNewData(this.newsCheckboxGroup, this.ToLocalISOString(new Date())).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    this.newsList = data.data.data;
                }
            });
            // 向SignalR添加检索列表
            await SignalRService.addToNewsParameterList(this.newsCheckboxGroup);
        },
        ShowNewsAlert() {
            this.dialogNewsAlertVisible = true;
        },
        // 添加更多用户检索的新闻
        AddMoreAlertNews() {
            this.AddMoreNewsAlertLoading = true;
            // 获取获取用户筛选出来的新闻
            GetAlertNews(this.alertNewsIndex + 1).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    const newsPreprocessList = data.data.data.alertNewsList.map(item => item.newsPreprocess);
                    this.insertAlertNewsAtEnd(newsPreprocessList);
                    this.setMaxAlertNewsIndex(data.data.data.count / 20);
                }
                this.AddMoreNewsAlertLoading = false;
                this.alertNewsIndex = this.alertNewsIndex + 1;
            });
        },

        // 添加更多新闻
        AddMoreNews() {
            this.AddMoreNewsLoading = true;
            // 使用reduce方法找到最小的dateTime
            let earliest = this.newsList.reduce((min, news) => {
                return new Date(news.dateTime) < new Date(min.dateTime) ? news : min;
            }, this.newsList[0]);
            // 减1秒
            let date = new Date(earliest.dateTime);
            date.setSeconds(date.getSeconds() - 0.1);
            GetNewData(this.newsCheckboxGroup, this.ToLocalISOString(date)).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    this.newsList = [...this.newsList, ...data.data.data];
                }
                this.AddMoreNewsLoading = false;
            });
        },
        // 计算实时时间
        updateTime() {
            const now = new Date();
            const months = now.getMonth() + 1;
            const days = now.getDate();
            const hours = now.getHours();
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            const weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            const dayOfWeek = weekDays[now.getDay()];
            this.currentTime = `${months}月${days}日 ${hours}:${minutes}:${seconds} ${dayOfWeek}`;
        },

        getNotificationSettings(key) {
            const settings = Cookie.get(key);
            if (settings) {
                return JSON.parse(settings);
            } else {
                // 默认全选
                return {
                    sound: true,
                    voice: true,
                };
            }
        },
        saveNotificationSettings(key, settings) {
            Cookie.set(key, JSON.stringify(settings), { expires: 31 }); // 保存一月
        },
        // 更新普通新闻通知设置
        updateNormalNewsSettings(newSettings) {
            this.normalNewsNotificationSettings = newSettings;
            this.saveNotificationSettings('normalNewsNotificationSettings', newSettings);
        },

        // 更新警报新闻通知设置
        updateAlertNewsSettings(newSettings) {
            this.alertNewsNotificationSettings = newSettings;
            this.saveNotificationSettings('alertNewsNotificationSettings', newSettings);
        },

        // 转换SignalR的新闻数据字段
        transformNewsData(news) {
            return {
                category: news.Category || '',
                content: news.Content || '',
                dateTime: news.DateTime || '',
                picUrls: news.PicUrls || [],
                relevantCompany: news.RelevantCompany || '',
                relevantIndustry: news.RelevantIndustry || '',
                score: news.Score || 0,
                source: news.Source || '',
                title: news.Title || '',
                web: news.Web || '',
                summary: news.Summary || '' // 确保 summary 不为空
            };
        },
        // 初始化 SignalR
        async initializeSignalR() {
            // 监听全局事件 connectionError 来更新连接状态
            EventBus.$on('connectionError', (status) => {
                this.connectionError = status;
            });
            await SignalRService.start();
            SignalRService.on('SendRealTimeNews', this.handleRealTimeNews);
            SignalRService.on('SendUserNews', this.handleRealUserNews);
        },
        // 接收SignalR的实时新闻
        handleRealTimeNews(json) {
            const newNews = JSON.parse(json);
            if (Array.isArray(newNews)) {
                const transformedData = newNews.map(news => ({ ...this.transformNewsData(news), isAlert: false }));
                // 将新接收到的新闻数据放到newsList的前面
                this.newsList.unshift(...transformedData);
                console.log("收到新RealTimeNews", transformedData);

                // 将消息添加到队列中，排除已存在于警报新闻中的summary
                transformedData.forEach(news => {
                    if (!this.alertNewsSummaries.has(news.summary)) {
                        this.newsQueue.push(news);
                    }
                });

                // 如果没有在播放，开始播放队列中的消息
                if (!this.isPlaying) {
                    this.playNext();
                }
            }
        },
        // 接收SignalR的实时用户检索新闻
        handleRealUserNews(json) {
            const newNews = JSON.parse(json);
            if (Array.isArray(newNews)) {
                const transformedData = newNews.map(news => ({ ...this.transformNewsData(news), isAlert: true }));
                // 将新接收到的新闻数据放到newsList的前面
                this.insertAlertNewsAtFirst(transformedData);
                console.log("收到新RealUserNews", transformedData);

                // 将消息添加到队列中，并记录警报新闻的summary
                transformedData.forEach(news => {
                    this.alertNewsSummaries.add(news.summary);
                    // 移除队列中相同summary的普通新闻
                    this.newsQueue = this.newsQueue.filter(item => item.summary !== news.summary || item.isAlert);
                    this.newsQueue.push(news);
                });

                // 如果没有在播放，开始播放队列中的消息
                if (!this.isPlaying) {
                    this.playNext();
                }
            }
        },

        // 处理用户交互事件
        handleUserInteraction() {
            this.isUserInteracted = true; // 将用户交互标志设置为 true
        },

        // 播放和朗读队列中的下一条消息
        async playNext() {
            if (!this.isUserInteracted) {
                console.error("用户尚未与页面交互，无法播放音频");
                return;
            }

            if (this.newsQueue.length === 0) {
                this.isPlaying = false;
                return;
            }

            this.isPlaying = true;
            const newsItem = this.newsQueue.shift();

            // 检查用户是否选择了声音提示，如果是，则播放音频文件
            if (newsItem.isAlert && this.alertNewsNotificationSettings.sound) {
                await this.playAudio(this.alertNewsAudio);
            } else if (!newsItem.isAlert && this.normalNewsNotificationSettings.sound) {
                await this.playAudio(this.normalNewsAudio);
            }

            // 检查用户是否选择了语音播报，如果是，则朗读新闻内容
            if (newsItem.isAlert && this.alertNewsNotificationSettings.voice) {
                await this.readAloud(newsItem.summary);
            } else if (!newsItem.isAlert && this.normalNewsNotificationSettings.voice) {
                await this.readAloud(newsItem.summary);
            }

            // 继续播放队列中的下一条消息
            this.playNext();
        },
        // 播放音频文件并等待完成
        playAudio(audio) {
            return new Promise((resolve, reject) => {
                if (this.isUserInteracted) {
                    audio.play().then(resolve).catch(reject); // 播放音频文件
                } else {
                    this.$message({
                        showClose: true,
                        message: "初次打开网页，请点击屏幕空白处以播放音频",
                        type: 'warning' // 设置弹窗类型为警告
                    });
                    reject(new Error("用户尚未与页面交互，无法播放音频"));
                }
            });
        },

        // 使用Web Speech API合成语音并朗读文本内容
        readAloud(text) {
            return new Promise((resolve) => {
                if ('speechSynthesis' in window) {
                    const utterance = new SpeechSynthesisUtterance(text);
                    utterance.lang = 'zh-CN'; // 设置语言为中文
                    utterance.rate = 1.4; // 设置语速
                    utterance.onend = resolve;
                    window.speechSynthesis.speak(utterance);
                } else {
                    console.error('您的浏览器不支持语音合成');
                    resolve();
                }
            });
        },
        createKeyframes() {
            const itemCount = this.newsTypes.length;
            const itemHeight = 40; // 每个通知项的高度
            const stayDuration = 3; // 每条通知停留的时间（秒）
            const moveDuration = 0.5; // 每条通知滚动的时间（秒）
            const totalDuration = itemCount * (stayDuration + moveDuration); // 动画总持续时间（秒）

            let keyframes = `@keyframes roll {`;

            for (let i = 0; i < itemCount; i++) {
                const percentageStart = (i * (100 / itemCount)).toFixed(2);
                const percentageMid = ((i * (100 / itemCount)) + ((stayDuration / (stayDuration + moveDuration)) * (100 / itemCount))).toFixed(2);
                const percentageEnd = ((i + 1) * (100 / itemCount)).toFixed(2);
                const marginTop = -(i * itemHeight);

                keyframes += `
                    ${percentageStart}% {
                        margin-top: ${marginTop}px;
                    }
                    ${percentageMid}% {
                        margin-top: ${marginTop}px;
                    }
                    ${percentageEnd}% {
                        margin-top: ${marginTop - itemHeight}px;
                    }
                `;
            }

            keyframes += `
                100% {
                    margin-top: 10;
                }
            }`;

            // 插入动态生成的样式规则
            const styleSheet = document.createElement('style');
            styleSheet.type = 'text/css';
            styleSheet.innerText = keyframes;
            document.head.appendChild(styleSheet);

            // 应用动态生成的动画持续时间
            const noticeInner = this.$el.querySelector('.notice__inner');
            noticeInner.style.animationDuration = `${totalDuration}s`;
        },
        handleClose(done) {
            this.setUserLoginDialogVisible(false);
        },
        // 显示错误信息
        ErrorMessage(message) {
            this.$message({
                showClose: true,
                message: message,
                type: 'error'
            });
        },
        ...mapMutations(['setUserInfo', 'setUserLoginDialogVisible', 'setAlertNewsList', 'insertAlertNewsAtFirst', 'insertAlertNewsAtEnd', 'setMaxAlertNewsIndex']),
    },
    computed: {
        ...mapState({
            userInfo: state => state.tab.userInfo,
            alertNewsList: state => state.newsAlert.alertNewsList,
            maxAlertNewsIndex: state => state.newsAlert.maxAlertNewsIndex,
        }),
    },
    async mounted() {
        await this.initializeSignalR(); // 初始化 SignalR，并且已经调用了 SignalRService.start()
        window.addEventListener('click', this.handleUserInteraction);
        const newsCheckboxGroup = Cookie.get('newsCheckboxGroup');
        if (newsCheckboxGroup) {
            this.newsCheckboxGroup = JSON.parse(newsCheckboxGroup);
        }
        this.updateTime();
        setInterval(this.updateTime, 1000);
        await this.GetStartNews();
        if (this.userInfo) {
            const token = Cookie.get('token');
            // SignalR 的 token 注册
            await SignalRService.tokenAuthorize(token);
            // 获取用户筛选出来的新闻
            GetAlertNews(this.alertNewsIndex).then((data) => {
                if (data.data.code !== 0) {
                    this.ErrorMessage(data.data.message);
                } else {
                    const newsPreprocessList = data.data.data.alertNewsList.map(item => item.newsPreprocess);
                    this.setAlertNewsList(newsPreprocessList);
                    this.setMaxAlertNewsIndex(data.data.data.count / 20);
                }
            });
        }
        // 监听SignalR连接成功事件
        EventBus.$on('signalRConnected', this.GetStartNews);
        this.createKeyframes();
    },
    beforeDestroy() {
        SignalRService.stop();
    },
    components: {
        CommonSingleNews, CommonLogin, CommonUserNewsAlert
    }
};
</script>

<style lang="less" scoped>
.home {
    background-color: rgba(251, 251, 251, 1);
    width: 100%;

    .notice {
        width: 600px;
        height: 40px;
        margin-left: 20px;
        overflow: hidden;
        border-radius: 8px;
        border: 1px solid #eee;

        .notice__inner {
            margin-top: 0;
            animation: roll linear infinite;
        }

        .notice__item {
            margin-left: 10px;
            font-size: small;
            color: rgba(99, 99, 99, 1);
            height: 40px;
            line-height: 40px;
            padding: 0 12px;
            white-space: nowrap;
        }
    }

    .news {
        position: relative;
        margin-top: -10px;
        margin-right: 10px;
    }

    .red-line {
        position: absolute;
        left: 23px;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: rgba(232, 232, 232, 1);
        z-index: 0;
    }

    .blue-line {
        position: absolute;
        left: 23px;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: rgba(90, 156, 248, 0.3);
        z-index: 0;
    }

    .newsSearch {
        margin-left: 30px;
        border-radius: 20px;
        width: 300px;
    }

    .userFocus {
        position: relative;
        margin-top: 10px;
        background-color: rgba(180, 253, 255, 0.1);
        width: 100%;
        height: 100%;
        border-left: 1px solid rgba(187, 187, 187, 1);
        border-right: 1px solid rgba(239, 239, 239, 1);
    }

    //弹跳动画
    .loading-dots {
        display: inline-block;
        position: relative;
        width: 100px;
        /* Adjust width to accommodate more dots */
        height: 14px;
    }

    .loading-dots div {
        position: absolute;
        top: 6px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #4d90fe;
        animation: loading-dots 2s ease-in-out infinite;
    }

    .loading-dots div:nth-child(1) {
        left: 0;
        animation-delay: -0.5s;
    }

    .loading-dots div:nth-child(2) {
        left: 16px;
        animation-delay: -0.25s;
    }

    .loading-dots div:nth-child(3) {
        left: 32px;
    }

    .loading-dots div:nth-child(4) {
        left: 48px;
        animation-delay: 0.25s;
    }

    @keyframes loading-dots {
        0% {
            transform: scale(0);
        }

        25% {
            transform: scale(1.5);
        }

        50% {
            transform: scale(1);
        }

        75% {
            transform: scale(1);
        }

        100% {
            transform: scale(0);
        }
    }
}
</style>

import httpHty from '../utils/htyRequest'
import httpNews from '../utils/newsRequest'


//#region 登录模块

//发送验证码
export const sendPhoneVerificationCode = (phoneNumber) => {
    const params = { phoneNumber: phoneNumber }
    return httpHty.get('MessageInfo/SendPhoneVerificationCode', { params })
};
//验证码登录功能
export const loginWithVerificationCode = (phoneNumber, verificationCode) => {
    return httpHty.post('Auth/LoginWithVerificationCode',
        {
            phoneNumber: phoneNumber,
            verificationCode: verificationCode
        })
};
//账号密码登录功能
export const loginWithPassword = (username, password) => {
    const params = { username: username, password: password }
    return httpHty.get('Auth/LoginWithPassword', { params })
};
//获取token信息
export const getTokenInformation = (token) => {
    const params = { token: token }
    return httpHty.get('Auth/GetTokenInformation', { params })
};
// 更新用户信息功能
export const updateUserInfo = (userID, nickName, url, isDelete) => {
    const data = { userID, nickName, url, isDelete };
    return httpHty.post('Auth/UpdateUserInfo', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

//#endregion


//#region 文件服务


// 上传文件的函数
export const uploadFileWithFilePath = (file, remark) => {
    const formData = new FormData();
    formData.append('uploadedFile', file);
    // 将 remark 作为查询参数附加到 URL 中
    const url = `Data/UploadFileWithIFormFile?remark=${encodeURIComponent(remark)}`;
    return httpHty.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


//#endregion


//#region 礼品卡服务

//使用礼品卡
export const userGiftCardVerificationCode = (giftCardStr) => {
    const params = { giftCardStr: giftCardStr }
    return httpHty.get('GiftCard/UseGiftCard', { params })
};

//获取vip到期时间
export const getVipInfoDate = () => {
    return httpHty.get('GiftCard/GetVipInfoDate')
};

//#endregion


//#region 用户新闻提醒服务

//获取用户新闻提醒服务
export const GetNewsAlert = () => {
    return httpNews.get('NewsAlert/GetNewsAlert')
};

//插入新闻提醒字段，ID不为0则修改
export const InsertNewsAlert = (newsAlertList) => {
    return httpNews.post('NewsAlert/InsertNewsAlert', newsAlertList)
};

//移除新闻提醒字段
export const RemoveNewsAlert = (intList) => {
    return httpNews.post('NewsAlert/RemoveNewsAlert', intList)
};


//获取用户检索的新闻
export const GetAlertNews = (page) => {
    const params = { pageIndex: page, pageSize: 20 }
    return httpNews.get('NewsAlert/GetAlertNews', { params })
};


//#endregion


//获取新闻数据
export const GetNewData = (newsType, time) => {
    return httpNews.post('News/GetNewData?count=20',
        {
            newsType: newsType,
            time: time
        })
};

//新闻数据搜索
export const GetDbNewsProcessedContentSearch = (searchContent, pageIndex) => {
    //const params = { searchContent: searchContent, pageIndex: pageIndex, pageSize: 20 }
    return httpNews.post(`News/GetDbNewsProcessedContentSearch?searchContent=${searchContent}&pageIndex=${pageIndex}&pageSize=20`)
};

